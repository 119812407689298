<template>
  <CRow>
    <CCol :xs="12">
      <CCard class="mb-4">
        <CCardHeader> <strong>Product Reports</strong> </CCardHeader>
        <CRow
          class="g-3 align-self-end"
          style="margin-right: 5%; margin-top: 1%"
        >
          <CCol xs="auto" class="align-self-end">
            <CFormInput
              type="text"
              v-model="search"
              @change="searchData"
              placeholder="Search"
            />
          </CCol>
        </CRow>
        <CCardBody>
          <CTable hover>
            <CTableHead>
              <CTableRow>
                <CTableHeaderCell scope="col">No</CTableHeaderCell>
                <CTableHeaderCell scope="col">Product</CTableHeaderCell>
                <CTableHeaderCell scope="col">Category</CTableHeaderCell>
                <CTableHeaderCell scope="col">Reasons</CTableHeaderCell>
                <CTableHeaderCell scope="col">Posted By</CTableHeaderCell>
                <CTableHeaderCell scope="col"
                  >Go To Product Detail</CTableHeaderCell
                >
              </CTableRow>
            </CTableHead>
            <CTableBody v-for="(p, index) in checkTest()" :key="index">
              <CTableRow>
                <CTableHeaderCell scope="row">{{
                  index + 1 + (currentPage - 1) * perPage
                }}</CTableHeaderCell>
                <CTableDataCell>{{ p.productName }}</CTableDataCell>
                <CTableDataCell>{{ p.categoryName }}</CTableDataCell>
                <CTableDataCell>{{ p.reasonName }}</CTableDataCell>
                <CTableDataCell>{{ p.name }}</CTableDataCell>

                <CTableDataCell>
                  <router-link :to="`/pages/productdetails/${p.productId}`">
                    <CIcon name="cil-search"
                  /></router-link>
                </CTableDataCell>
              </CTableRow>
            </CTableBody>
          </CTable>
          <div class="overflow-auto">
            <b-pagination
              v-model="currentPage"
              :total-rows="$store.state.productReport.totalRecord"
              :per-page="perPage"
              aria-controls="my-table"
              :change="checkPage(currentPage)"
            ></b-pagination>
          </div>
        </CCardBody>
      </CCard>
    </CCol>
  </CRow>
</template>

<script>
// import product from '../../apis/product.js'
import productReports from '../../apis/productReports.js'

export default {
  name: 'Product Reports',
  data() {
    return {
      productId: 0,
      visibleStaticBackdrop: false,
      isDisable: 0,
      isLoading: false,
      currentPage: 1,
      perPage: 5,
      totalRows: 1,
      search: '',
    }
  },
  mounted() {
    setTimeout(() => {
      this.$store.dispatch('getAllProductReport', {
        skip: this.skip,
        take: this.take,
        search: '',
      })
    }, 500)
  },
  methods: {
    checkTest() {
      var data = this.$store.state.productReport.productReports
      return data
    },
    checkPage(page) {
      setTimeout(() => {
        this.$store.dispatch('getAllProductReport', {
          skip: this.skip,
          take: this.take,
          search: this.search,
        })
        console.log(page)
      }, 500)
    },
    async updateProduct() {
      this.isLoading = true
      if (this.isDisable === 1) {
        const response = await product.enableProduct({
          productId: this.productId,
        })
        if (response.isSuccess) {
          this.$store.dispatch('getAllProductReport', {
            skip: this.skip,
            take: this.take,
          })
          this.visibleStaticBackdrop = false
          this.isLoading = false
        }
      } else {
        const response = await product.disableProduct({
          productId: this.productId,
        })
        if (response.isSuccess) {
          this.$store.dispatch('getAllProductReport', {
            skip: this.skip,
            take: this.take,
          })
          this.visibleStaticBackdrop = false
          this.isLoading = false
        }
      }
    },
    searchData() {
      setTimeout(() => {
        this.currentPage = 1
        this.skip = 0
        this.take = this.perPage
        this.checkPage(1)
      }, 500)
    },
  },
  computed: {
    skip: function () {
      return (this.currentPage - 1) * this.perPage
    },
    take: function () {
      return this.perPage
    },
  },
}
</script>
